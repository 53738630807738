import { React } from 'react'
import { slug, icap, tr } from '../../../../global-pure.js'
import PageLayout from '../../../../containers/page/PageLayout.jsx'
import cardPic from '../../../../assets/jobs/cleaning.jpg'

export const card = {
  title: 'cleaning-ccs:title',
  subtitle: 'cleaning-ccs:subtitle',
  routeBuilder: () => `/${slug(tr('contact'))}?n=1&m=${tr('I am interested in doing @').replace('@', `${tr('cleaning')}, ${tr(card.title)}, ${tr(card.subtitle)}`)}`,
  imgUrl: cardPic,
  call: 'inquire now',
}

const Job = () => {
  return (
    <PageLayout>
      <div className='side-divider'>
        <div className='content'>
          <h1>{icap(tr(card.title))}</h1>
          <h2>{tr(card.subtitle)}</h2>
        </div>
      </div>
    </PageLayout>
  )
}

export default Job