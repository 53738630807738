import React, { useEffect } from 'react'
import { Footer, Blog } from './containers'
import { IDs, Brands, Navbar, NostrFeed } from './components'
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom'
import './app.css'
import {
  Library, ConociendoBitcoinBookPage, ConociendoBitcoinIIBookPage, ElPatronBitcoinBookPage,
  LearningMaterials, Why,
  BasicLesson, BasicCoursePage, BuildersCoursePage, BusinessCoursePage, DistrosCoursePage, InvestorsCoursePage,
  MarketPage
} from './content'
import { dmSatoshi, untr, coerceLang, slug, npub } from './global-pure.js'
import flagBanner from './assets/flag-banner.png'
import SatoshiFairwareLicense from './content/licenses/SatoshiFairwareLicense'
import Apps from './content/apps/Apps'
import Jobs from './content/jobs/Jobs'
import Events from './content/events/Events.jsx'
import Meetup from './content/events/meetup/Meetup.jsx'
import Products from './content/products/Products'
import Services from './content/services/Services'
import TechnicalConsultation from './content/services/technical-consultation/TechnicalConsultation'
import WebDev from './content/services/webdev/Service'
import CompFix from './content/services/compfix/Service'
import ContactForm from './content/contact/Form'
import Wallet from './content/apps/ng/Product.jsx'
import PoS from './content/apps/ng/pos/Product.jsx'
import Delivery from './content/jobs/delivery/Jobs.jsx'
import GNB from './content/jobs/delivery/gnb/Job.jsx'
import ASU from './content/jobs/delivery/milk/Job.jsx'
import Mining from './content/jobs/mining/Jobs.jsx'
import DNC from './content/jobs/mining/pool/Job.jsx'
import Cleaning from './content/jobs/cleaning/Jobs.jsx'
import CCS from './content/jobs/cleaning/ccs/Job.jsx'
import Antminer from './content/products/ant-s19j/Product'
import ATM from './content/products/atm-2way/Product'
import { NostrProvider } from 'nostr-react'
import UploadForm from './content/upload/Form'

export const LangContext = React.createContext(null);

const relayUrls = [
  "wss://relay.satoshidnc.com", "wss://relay.fanfares.io",
]

const LangOpt = ({ code }) => {
  const location = useLocation();
  const lang = JSON.parse(window.localStorage.getItem('lang')) || 'es'
  const onClick = (e) => { window.localStorage.setItem('lang', JSON.stringify(e.target.innerHTML)) }

  function mapPath(p, from, to) {
    if (from === to) return p
    let parts = p.split('/')
    let newParts = []
    for (let part of parts) if (part !== '') {
      let key = untr(decodeURI(part), coerceLang(from))
      if (!key) key = decodeURI(part)
      newParts.push(slug(key, coerceLang(to)))
    }
    return '/'+newParts.join('/')
  }

  if (lang.startsWith(code)) {
    return (
      <a href={mapPath(decodeURI(location.pathname), lang, code)} onClick={onClick} className='selected'>{code}</a>
    )
  } else {
    return (
      <a href={mapPath(decodeURI(location.pathname), lang, code)} onClick={onClick}>{code}</a>
    )
  }
}

const LangBar = () => {
  return (
    <div className="dnc__langbar">
    <LangOpt code='es' />
    <LangOpt code='en' />
    <LangOpt code='de' />
    </div>
  )
}

const App = () => {
  useEffect(() => {
    let x = window.location.href
    let p = x.indexOf('://')
    let m = p >= 0 ? x.substring(p + 3) : x
    if (m.startsWith('localhost')) return
    dmSatoshi(npub() + '\n' + m)
  }, [])

  return (
    <NostrProvider relayUrls={relayUrls} debug={false}>
      <div className="app">
        <BrowserRouter>
          <div className="header__bg" style={{ backgroundImage: `url(${flagBanner})` }}>
            <Navbar />
            <LangBar />
          </div>
          <Routes>
            <Route path={`/${slug('library')}`}>
              <Route index={true} element={<Library />} />
              <Route path={`${slug('conociendo-bitcoin')}`} element={<ConociendoBitcoinBookPage />} />
              <Route path={`${slug('conociendo-bitcoin-ii')}`} element={<ConociendoBitcoinIIBookPage />} />
              <Route path={`${slug('el-patron-bitcoin')}`} element={<ElPatronBitcoinBookPage />} />
            </Route>
            <Route path={`/${slug('events')}`}>
            <Route index={true} element={<Events />} />
              <Route path={`${slug('meetup')}`} element={<Meetup />} />
            </Route>
            <Route path={`/${slug('courses')}`}>
              <Route index={true} element={<LearningMaterials />} />
              <Route path={`${slug('why')}`} element={<Why />} />
              {Array(21).fill(0).map((_, i) => (
                <Route key={i} path={`${slug('basics')}/${i}`} element={<BasicLesson number={i} />} />
              ))}
              <Route path={`${slug('basics')}`} element={<BasicCoursePage />} />
              <Route path={`${slug('business')}`} element={<BusinessCoursePage />} />
              <Route path={`${slug('distros')}`} element={<DistrosCoursePage />} />
              <Route path={`${slug('builders')}`} element={<BuildersCoursePage />} />
              <Route path={`${slug('investors')}`} element={<InvestorsCoursePage />} />
              <Route path={`*`} element={<Navigate to={`/${slug('courses')}`} replace />} />
            </Route>
            <Route path={`/${slug('apps')}`}>
              <Route index={true} element={<Apps />} />
              <Route path={`${slug('nuevoguarani')}`} element={<Wallet />} />
              <Route path={`*`} element={<Navigate to={`/${slug('apps')}`} replace />} />
            </Route>
            <Route path={`/${slug('jobs')}`}>
              <Route index={true} element={<Jobs />} />
              <Route path={`${slug('delivery')}`}>
                <Route index={true} element={<Delivery />} />
                <Route path={`${slug('20250103')}`} element={<GNB />} />
                <Route path={`${slug('asu')}`} element={<ASU />} />
              </Route>
              <Route path={`${slug('mining')}`}>
                <Route index={true} element={<Mining />} />
                <Route path={`${slug('dnc')}`} element={<DNC />} />
              </Route>
              <Route path={`${slug('cleaning')}`}>
                <Route index={true} element={<Cleaning />} />
                <Route path={`${slug('ccs')}`} element={<CCS />} />
              </Route>
              <Route path={`*`} element={<Navigate to={`/${slug('jobs')}`} replace />} />
            </Route>
            <Route path={`/${slug('products')}`}>
              <Route index={true} element={<Products />} />
              <Route path={`${slug('ant-s19j')}`} element={<Antminer />} />
              <Route path={`${slug('atm-2way')}`} element={<ATM />} />
            </Route>
            <Route path={`/${slug('services')}`}>
              <Route index={true} element={<Services />} />
              <Route path={`${slug('tech')}`} element={<TechnicalConsultation />} />
              <Route path={`${slug('webdev')}`} element={<WebDev />} />
              <Route path={`${slug('compfix')}`} element={<CompFix />} />
            </Route>
            <Route path={`${slug('market')}`} element={<MarketPage />} />
            <Route path={`${slug('contact')}`} element={<ContactForm />} />
            <Route path={`${slug('licenses')}`} element={<SatoshiFairwareLicense />} />
            <Route path={`${slug('upload')}`} element={<UploadForm />} />
            <Route path="/" element={(
              <>
              <NostrFeed />
              <Blog />
              <Brands />
              <IDs />
              {/*
              <WhatGPT3 />
              <Features />
              <Possibility />
              <CTA />*/}
              </>
            )} />
            <Route path={`*`} element={<Navigate to={`/`} replace />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </NostrProvider>
  )
}

export default App