import { React } from 'react'
// import './services.css'
import { icap, tr, slug } from '../../../global-pure.js'
import PageLayout from '../../../containers/page/PageLayout.jsx'
import { card as poolCard } from './pool/Job.jsx'
import Card from '../../../components/card/Card.jsx'
import cardPic from '../../../assets/products/ant-s19j.png'

export const card = {
  title: 'mining:title',
  blurb: 'mining:blurb',
  subtitle: 'mining:subtitle',
  routeBuilder: () => `/${slug(tr('jobs'))}/${slug(tr('mining'))}`,
  imgUrl: cardPic,
  call: 'see jobs',
  // ask: <AskLink text={'purchase now'} prefill={'ant-s19j:question'} />
}

const Delivery = () => {
  return (
    <PageLayout>
      <div className='content'>
        <h1>{icap(tr('mining jobs'))}</h1>
        <Blog />
      </div>
    </PageLayout>
  )
}

const Blog = () => {
  return (
    <div className='dnc__blog-container'>
      <div className='dnc__blog-course_groupB'>
        <Card card={poolCard} />
      </div>
    </div>
  )
}

export default Delivery