import { slug, tr } from '../global-pure.js'
import marketPic from '../assets/market.png'

const naddr_en = 'naddr1qqjxxctrvvuryvf395erzdec956xyv3c95urset995mxxefnvvuxxdmr8ymrqqg6waehxw309aex2mrp0yh8xct5daeks6tyde3jucm0d5pzpg9rrslxc46d43g8hjcp22fq8r7dhzydnlykp6xzqasw2mrkrl34qvzqqqr4gvwfq8sp'

const init_en = (db) => { db['en-US'] = {
  '{M}/{D}/{Y} {hh}:{mm}': '{M}/{D}/{Y} {hh}:{mm}',
  'Block Clock': 'Block Clock',
  'Today is the ': 'Today is the ',
  ' day of the ': ' day of the ',
  ' month of the ': ' month of the ',
  ' year of Bitcoin in block time': ' year of Bitcoin in block time',
  'ordinal-and': ' ',
  'first': 'first',
  'second': 'second',
  'third': 'third',
  'fourth': 'fourth',
  'fifth': 'fifth',
  'sixth': 'sixth',
  'seventh': 'seventh',
  'eighth': 'eighth',
  'ninth': 'ninth',
  'tenth': 'tenth',
  'eleventh': 'eleventh',
  'twelfth': 'twelfth',
  'thirteenth': 'thirteenth',
  'fourteenth': 'fourteenth',
  'fifteenth': 'fifteenth',
  'sixteenth': 'sixteenth',
  'seventeenth': 'seventeenth',
  'eighteenth': 'eighteenth',
  'nineteenth': 'nineteenth',
  'twentieth': 'twentieth',
  'thirtieth': 'thirtieth',
  'fortieth': 'fortieth',
  'fiftieth': 'fiftieth',
  'sixtieth': 'sixtieth',
  'seventieth': 'seventieth',
  'eightieth': 'eightieth',
  'ninetieth': 'ninetieth',
  'ord-twenty': 'twenty',
  'ord-thirty': 'thirty',
  'ord-forty': 'forty',
  'ord-fifty': 'fifty',
  'ord-sixty': 'sixty',
  'ord-seventy': 'seventy',
  'ord-eighty': 'eighty',
  'ord-ninety': 'ninety',
  'events': 'events',
  'ASU Meetup': 'ASU Meetup',
  'CDE Meetup': 'CDE Meetup',
  'DNC Reunión': 'DNC Meetup',
  'DNC Reunión (inglés)': 'DNC Meetup (English)',
  'DNC Reunión (alemán)': 'DNC Meetup (German)',
  'DNC Reunión (castillano)': 'DNC Meetup (Spanish)',
  'ENC Meetup': 'ENC Meetup',
  'library': 'library',
  'date': 'date',
  'price': 'price',
  'location': 'location',
  'technical consultation': 'Technical Consultation',
  'Schedule a visit or video call to learn how we can serve you.': 'Schedule a visit or video call to learn how we can serve you.',
  'read full article': 'read the full article',
  'begin the course': 'begin the course',
  'register now': 'register now',
  'get it now': 'get it now',
  'try it now': 'try it now',
  'see features': 'see features',
  'see apps': 'see apps',
  'see jobs': 'see jobs',
  'course details': 'course details',
  'learn': 'learn',
  'learn more': 'learn more',
  'inquire now': 'inquire now',
  'purchase now': 'purchase now',
  'product details': 'product details',
  'browse now': 'browse now',
  'courses': 'courses',
  'apps': 'apps',
  'jobs': 'jobs',
  'I am interested in doing @': 'I am interested in doing "@"',
  'delivery': 'delivery',
  'delivery jobs': 'delivery jobs',
  'delivery:title': 'delivery',
  'delivery:blurb': 'Earn by transporting products from point A to point B',
  'delivery:subtitle': '',
  'delivery-gnb:title': 'weekly, Friday morning',
  'delivery-gnb:subtitle': '10km from Siraty, Nueva Colombia to Isla Alta, Nueva Colombia',
  'delivery-milk:title': 'weekly',
  'delivery-milk:subtitle': '42km from Nueva Colombia to Asunción (Mbocayaty), with refrigeration/ice',
  'mining': 'mining',
  'mining jobs': 'mining jobs',
  'mining:title': 'mining',
  'mining:blurb': 'Earn by mining Bitcoin',
  'mining:subtitle': '',
  'mining-dnc:title': 'mine Bitcoin at your site',
  'mining-dnc:subtitle': 'multiple options available',
  'cleaning': 'cleaning',
  'cleaning jobs': 'cleaning jobs',
  'cleaning:title': 'cleaning',
  'cleaning:blurb': 'Earn by cleaning',
  'cleaning:subtitle': '',
  'cleaning-ccs:title': '1-2 hours, every Friday morning at 9:00',
  'cleaning-ccs:subtitle': 'Nueva Colombia',

  'nuevoguarani': 'newguarani',
  'wallet': 'wallet',
  'why': 'why',
  'services': 'services',
  'meetup': 'meetup',
  'market': 'market',
  'contact': 'contact',
  'basics': 'basics',
  'lesson': 'lesson',
  'next': 'next',
  'contact form': 'Contact Form',
  'How would you like to be contacted?': 'How would you like to be contacted?',
  'What is your message?': 'What is your message?',
  'optional': 'optional',
  'required': 'required',
  'nostr': 'nostr',
  'name': 'name',
  'e-mail': 'E-Mail',  
  'message': 'message',
  'send': 'send',
  'check required fields': 'check required fields',
  'or': 'or',
  'Questions?': 'Questions?',
  'ask now': 'ask now',
  'Bugs?': 'Bugs?',
  'report a bug': 'report a bug',
  'Hi, I would like to ask a question about': 'Hi, I would like to ask a question about',
  'techcons:question': 'Hi, I would like to ask a question about technical consultation.\n...\n',
  'webdev:question': 'Hi, I would like to ask a question about website development.\n...\n',
  'compfix:question': 'Hi, I would like to ask a question about computer repair.\n...\n',
  'Educational Resources': 'Educational Resources',
  'Copyright © {years}. All rights reserved.': 'Copyright © 2023-2025. All rights reserved.',
  'dates': 'dates',
  'schedule': 'schedule',
  'course options': 'course options',
  'publication date': 'publication date',
  'inquire about check-out': 'inquire about check-out',
  'this course is for': 'this course is for',
  'in this course, you will': 'in this course, you will',
  'this course includes': 'this course includes',
  'NOT included in course': 'NOT included in course',
  'prerequisites': 'prerequisites',
  'payment methods': 'payment methods',
  'registration': 'registration',
  'everyone': 'everyone',
  'owners': 'owners',
  'business owners': 'business owners',
  'owners of businesses which already have delivery services': 'owners of businesses which already have delivery services',
  'all employees who will handle online orders': 'all employees who will handle online orders',
  'high net worth individuals': 'high net worth individuals',
  'individuals with savings': 'individuals with savings',
  'individuals with income to spare': 'individuals with income to spare',
  'none': 'none',
  'Guarani cash': 'Guarani cash',
  'Guarani, bank deposit': 'Guarani, bank deposit',
  'Dollars, PayPal': 'Dollars, PayPal',
  'Dollars, bank deposit': 'Dollars, bank deposit',
  'in-person, upon payment': 'in-person, upon payment',
  'online, effective upon receipt of payment': 'online, effective upon receipt of payment',

  'Featured video!': 'Featured video!',
  'Click to watch now!': 'Click to watch now!',


  /* LIBRARY PAGE */

  'library:title': 'Community Bitcoin Library',
  'library:intro': 'We have the following physical books in our library. To access a book, please contact us. We are {link:lightning:biblioteca@satoshidnc.com^accepting donations} toward the purchase costs of these and additional books to have available to our community.',
  'library:other-title': 'Additional Resources',
  'library:other-intro': 'The following books by Nelson Cardozo are available online.',

  'el-patron-bitcoin:title': 'El patrón Bitcoin',
  'el-patron-bitcoin:subtitle': 'La alternativa descentralizada a los bancos centrales',
  'el-patron-bitcoin:author': 'Saifedean Ammous (Author), Mercedes Vaquero Granados (Translator)',
  'el-patron-bitcoin:date': 'October 2, 2018',
  'el-patron-bitcoin:link': '/contact?n=1&m=Hello%2C%20I%20understand%20that%20you%20have%20the%20book%20"El%20patrón%20Bitcoin"%20available%20in%20the%20District%20of%20New%20Colombia.%20I%20would%20like%20to%20borrow%20it%2C%20please.%20You%20may%20contact%20me%20using%20the%20contact%20information%20I%20have%20provided.%20Thank%20you.',

  'conociendo-bitcoin:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin:subtitle': '¿Se puede llegar a comprender todo sobre bitcoin? Se se puede.',
  'conociendo-bitcoin:author': 'Nelson Cardozo (Author)',
  'conociendo-bitcoin:date': '',

  'conociendo-bitcoin-ii:title': 'Conociendo Bitcoin',
  'conociendo-bitcoin-ii:subtitle': 'Orígens del dinero, que es bitcoin, aprende a ahorrar con bitcoins',
  'conociendo-bitcoin-ii:author': 'Nelson Cardozo (Author)',
  'conociendo-bitcoin-ii:date': '',


  'meetup:title': 'Welcome!',
  'meetup:location': 'District of New Colombia',
  'meetup:date': 'Sunday, September 15, at 10:00 a.m.',
  'meetup:price': '5 Gs (basically nothing), paid via the Lightning network.',
  'meetup:content.1': 'Please register to attend (check-in will be required). This helps us plan and keeps the group focused on actively participating in the bitcoin circular economy.',
  'meetup:content.2': 'For exact location and/or assistance with registration, please make contact via {link:https://chat.whatsapp.com/KiFnEElpETfH308PtN47Yr^WhatsApp}.',


  'Bitcoin Courses': 'Bitcoin Courses',
  'courses:intro': 'Satoshi’s courses are mission-focused to facilitate Bitcoin awareness and adoption in Paraguay for the mutual benefit of local businesses, individuals, foreign visitors, and immigrants. Together we are building the economy of the future in Paraguay. To understand the context of our mission, please read our short introduction:',
  'Course Selection': 'Course Selection',
  'Course Selection.1': 'The courses above are each focused on a specific use case. We believe this provides the optimal routes to success for our clients and community. Please review the course descriptions and select the course(s) that best match your particular situation. If you have any questions, please reach out to us. You might also be interested in scheduling an individual consultation for cases where group training is not preferred or available.',
  'Course Selection.2': '(Note for full-day courses: meals are NOT provided. We encourage bringing your own lunch or exploring local restaurants.)',
  'Refund Policy': 'Refund Policy',
  'Refund Policy.1': 'We strive to provide the best full-service training and support services possible for the long-term care of our clients. Your success is the measure of our success. We are confident that you will be impressed with the content and quality of our courses.',
  'Refund Policy.2': 'In the case that a course needs to be canceled for any reason before the start date, we will make a best-effort attempt to refund course registrations. However, please be aware that for courses that include hardware, we cannot refund equipment costs; the purchased equipment belongs to you. Refunds after the start date are subject to the discretion of Satoshi. Thank you for your understanding.',

  'basics:desc1': 'Please proceed to Bitcoin Magazine’s ',
  'basics:desc2': '21 Days of Bitcoin',
  'basics:desc3': ' course.',
  'Week {n}:': 'Week {n}:',

  'basics-course:date': 'September 14, 2023 - October 7, 2023',
  'basics-course:title': 'Bitcoin Basics',
  'basics-course:blurb': 'Makes you smart about Bitcoin with all of the essentials to understand how Bitcoin works.',
  'basics-course:subtitle': 'This is a three-week course covering the basics of Bitcoin.',
  'basics:option1': 'Schedule A: 1 hour per day for three full weeks, 8:00 a.m. - 9:00 a.m.',
  'basics:option2': 'Schedule B: 3 full days, one day per week (on the weekend)',
  'basics:bullet1': 'Learn everything you need to know to begin your Bitcoin journey.',
  'basics:bullet2': 'Learn terms, concepts, and vocabulary to speak with and understand Bitcoiners.',
  'basics:bullet3': 'Perform your first exchange from Guarani to Bitcoin.',
  'basics:bullet4': 'Make your first purchase using Bitcoin (10,000 Gs value, included).',

  'business-course:date': 'to be determined',
  'business-course:title': 'Bitcoin for Businesses',
  'business-course:blurb': 'Equips business owners with point-of-sale tools and training in Paraguay.',
  'business-course:subtitle': 'This course equips business owners with point-of-sale tools and training in Paraguay.',
  'business:option1': '2 full days, individually scheduled 1 day off-site, 1 day on-site',
  'business:bullet1': 'Point of sale system for Bitcoin and Guarani cash',
  'business:bullet2': 'Cashier training for owner/registrant only (owner will easily train other cashiers)',
  'business:bullet3': 'One month wallet service (can be extended if Builders Course is not utilized)',
  'business:bullet4': 'Everything you need for making sales in Bitcoin',
  'business:bullet5': 'A sign for your store indicating that you accept Bitcoin/Lightning payments',
  'business:bulletx1': 'Training for other cashiers (owner will easily train other cashiers)',
  'business:bulletx2': 'Ongoing wallet service (can be purchased separately, if Builders Course is not utilized)',
  'business:bulletx3': 'Material covered in the Basic Course (strongly recommended but not required)',
  'business:prereq1': 'Compatible smartphone or other device for point of sale (can be included at additional cost if desired)',
  'business:prereq2': 'Internet at point of sale location',

  'distros-course:date': 'to be determined',
  'distros-course:title': 'Bitcoin for Distributors',
  'distros-course:blurb': 'Teaches distributors how to take online orders using Bitcoin in Paraguay.',
  'distros-course:subtitle': 'This course teaches distributors how to take online orders using Bitcoin in Paraguay.',
  'distros:option1': 'One full day',
  'distros:bullet1': 'Online ordering system training',
  'distros:bullet2': 'One month wallet service (can be extended if Builders Course is not utilized)',
  'distros:bullet3': 'Online marketplace',
  'distros:bullet4': 'Everything you need to run online store accepting Bitcoin',
  'distros:bulletx1': 'Data entry for your product line (can be contracted separately)',
  'distros:bulletx2': 'Ongoing wallet service (can be purchased separately if Builders Course is not utilized)',
  'distros:bulletx3': 'Material covered in the Basic Course (strongly recommended but not required)',
  'distros:prereq1': 'Compatible computer or other device',
  'distros:prereq2': 'Internet at order-taking location',

  'builders-course:date': 'to be determined',
  'builders-course:title': 'Bitcoin for Builders',
  'builders-course:blurb': 'Steps you through building your own Bitcoin and Lightning node in Paraguay.',
  'builders-course:subtitle': 'This course steps students through building their own Bitcoin and Lightning node in Paraguay.',
  'builders:option1': '1 hour per day for three full weeks, 8:00 a.m. - 9:00 a.m.',
  'builders:bullet1': 'Student-constructed Bitcoin node',
  'builders:bullet2': 'Full copy of the Bitcoin blockchain (500GB download savings)',
  'builders:bullet3': 'Self-custody lightning node',
  'builders:bullet4': 'Funded and balanced lightning payment channel',
  'builders:bullet5': 'Everything you need to maintain your node',
  'builders:bulletx1': 'Ongoing balancing of channels (because it is the owner’s funds)',
  'builders:bulletx2': 'Maintenance (can be purchased separately)',
  'builders:prereq1': 'Basic Course (or test-out)',
  'builders:prereq2': 'Internet at node’s intended site',

  'investors-course:date': 'to be determined',
  'investors-course:title': 'Bitcoin for Investors',
  'investors-course:blurb': 'Guides investors in the various ways to invest in Bitcoin in Paraguay.',
  'investors-course:subtitle': 'This course guides investors in the various ways to invest in Bitcoin in Paraguay.',
  'investors:option1': '3 full days, one day per week (Sunday)',
  'investors:bullet1': 'The material in the basic course, with a different emphasis',
  'investors:bullet2': 'Instruction on Bitcoin security protocols',
  'investors:bullet3': 'Instruction on Bitcoin mining as an investment',
  'investors:bullet4': 'Your first $50 invested in self-custody Bitcoin (you can add more)',

  'why:title': 'Why is Bitcoin moving to Paraguay?',
  'why:blurb': 'Bitcoin and Paraguay are a match made in heaven! This short article explains the key points you need to know about the relocation of Bitcoin mining operations to Paraguay, and what implications it has for the world.',
  'why.1': 'Paraguay is the ideal location for Bitcoin mining for the following reasons:',
  'why.2': 'tag:ol',
  'why.2.1': 'Paraguay has 10 times more available electricity than it can presently use, which is currently lost revenue. Bitcoin miners can readily make use of this energy, providing revenue to Paraguay in return. It is a win-win situation for the Bitcoin mining companies and for the country of Paraguay.',
  'why.2.2': 'The treaty between Paraguay and Brazil is set for renegotiation this year, 2023. Bitcoin mining potential in Paraguay gives the nation a bargaining chip to help negotiate better and more profitable terms than in the past. Bitcoin mining operations can relocate and establish operation very quickly and the benefits are immediate.',
  'why.2.3': 'Paraguay’s energy production is 99.9% clean hydroelectric power. This means that Bitcoin mining facilities that relocate to Paraguay from power grids that are less environmentally friendly will be making a positive and quantifiable shift towards a better world.',
  'why.3': 'The three points above are the natural benefit of companies seeking to earn profits as part of their business operations. Paraguay’s comparatively inexpensive energy prices make it a win for the Bitcoin mining industry, while the domestic consumption of electricity makes it a win for the country.',
  'why.4': 'As competitive and circumstantial factors bring to bear more and more pressure on Bitcoin mining, more and more mining facilities will want to relocate to Paraguay, especially as a result of the Bitcoin “halving” that is coming in the spring of 2024. After the “halving,” only the best positioned and most cost-efficient mining companies will be able to survive, reaping an increased share of mining rewards as a result. In this highly competitive business, Paraguay stands to become the leading nation for Bitcoin mining in the world.',
  'why.5': 'For a small land-locked country like Paraguay, this is a growth opportunity of high value. The interest of foreign nationals from every part of the globe will inevitably generate more than just energy revenue. There will also be importation of hardware and technology, tourist revenue, contributions to the general economy, and more technological development and innovation.',
  'why.6': 'Easily overlooked is the fact that the newly mined Bitcoin earned as revenue from mining operations generally belongs to the owners of the mining hardware, and these individuals and companies will be gaining a vested interest in Paraguay. Their revenue—the money of the future (i.e. Bitcoin)—will be seeking to percolate into the economy of Paraguay, opening opportunities for the general population to earn Bitcoin alongside the Guaraní. For the nation, this in turn will become another channel of IVA revenue flowing through Paraguayan businesses.',
  'why.7': 'Just as the energy produced at Itaipu is clean energy, so the Bitcoin produced in mining operations is clean money with no negative history. If Paraguay acts wisely in this decisive year, the country can succeed in shedding the stigmas of the past and secure a future of prosperity that will be a win-win-win situation on all sides: a win for the country’s government, a win for its individual citizens, and a win for foreigners and the planet as a whole. As abundantly as the mighty Iguazú provides light for Paraguayan households today, it will provide their financial security tomorrow.',

  'lic:title': 'Satoshi Fairware License',
  'lic:blurb': 'If you develop a new program, and you want to be rewarded for your work without hindering its usefulness by the public, the best way to achieve this is to release it under the terms of the Satoshi Fairware License.',


  'status': 'status',
  'platforms': 'supported platforms',
  'software-platform:android-native': 'Android native',
  'software-platform:web-browser': 'Web - all devices',
  'software-platform:android-native-discontinued': 'Android native - discontinued',
  'software-platform:pwa': 'Progressive Web App (PWA) - tested in Chrome',
  'key-features': 'key features',
  'specifications': 'technical specifications',
  'license': 'source code and licensing',
  'security': 'security considerations',

  'ng:title': 'New Guaraní',
  'ng:blurb': `Paraguay’s point-of-sale experience is expanding with peer-to-peer electronic cash. These apps make buying and selling fun again!`,
  'ng:subtitle': 'Point of sale app for the masses',
  'ng:content': '',
  'ng:status': 'In active development with the app currently being beta tested in the field.',
  'ng:feature1': 'Works with both paper cash and electronic cash (in the same sale!)',
  'ng:feature2': 'As simple as a calculator to use',
  'ng:feature3': 'Visual product selection',
  'ng:feature4': 'Barcode support',
  'ng:feature5': 'Integrates with other software',
  'ng:spec1': 'For electronic cash functions, bitcoin is the currency and satoshi is the unit, with payments via the Lightning network.',
  'ng:spec2': 'The wallet for electronic cash is powered by LNbits (configurable; self-hosted or custodial)',
  'ng:spec3': 'Product prices and visuals can be sourced from a NostrMarket stall (via LNbits)',
  'ng:licensing': 'This software is open source under the ',
  'ng:licensing2': '.',
  'ng:security': 'All app data is stored in a single database on the local device (IndexedDB). Sensitive configuration data such as wallet read-only or full-access keys are not re-displayed. No password protection. Users are advised to regularly offload funds to a more secure wallet to minimize risk in case the device becomes compromised.',
  //https://pomcor.com/documents/KeysInBrowser.pdf

  'ng-pos:title': 'New Guaraní PoS',
  'ng-pos:blurb': `Paraguay’s point-of-sale experience is expanding with peer-to-peer electronic cash. These apps make buying and selling fun again!`,
  'ng-pos:subtitle': 'Point of sale app for the masses',
  'ng-pos:content': '',
  'ng-pos:status': 'In active development with the app currently being beta tested in the field.',
  'ng-pos:feature1': 'Works with both paper cash and electronic cash (in the same sale!)',
  'ng-pos:feature2': 'As simple as a calculator to use',
  'ng-pos:feature3': 'Visual product selection',
  'ng-pos:feature4': 'Barcode support',
  'ng-pos-pos:feature5': 'Integrates with other software',
  'ng-pos:spec1': 'For electronic cash functions, bitcoin is the currency and satoshi is the unit, with payments via the Lightning network.',
  'ng-pos:spec2': 'The wallet for electronic cash is powered by LNbits (configurable; self-hosted or custodial)',
  'ng-pos:spec3': 'Product prices and visuals can be sourced from a NostrMarket stall (via LNbits)',
  'ng-pos:licensing': 'This software is open source under the ',
  'ng-pos:licensing2': '.',
  'ng-pos:security': 'All app data is stored in a single database on the local device (IndexedDB). Sensitive configuration data such as wallet read-only or full-access keys are not re-displayed. No password protection. Users are advised to regularly offload funds to a more secure wallet to minimize risk in case the device becomes compromised.',

  'ng-wallet:title': 'New Guaraní Wallet',
  'ng-wallet:blurb': `Peer-to-peer cash exchange made easy, in the local currency and language!`,
  'ng-wallet:subtitle': 'A wallet app for the nation.',
  'ng-wallet:content': '',
  'ng-wallet:status': 'Stable release.',
  'ng-wallet:download-apk': `Download APK Now!`,
  'ng-wallet:feature1': 'Supports multiple accounts and/or the same account on multiple devices. (After a wallet is set up, it can be installed on more devices via URLs of {link:https://admin.ng.satoshidnc.com/name^this form}.)',
  'ng-wallet:feature2': 'Minimalist UI for simple, fast, and convenient use.',
  'ng-wallet:feature3': 'Alice initiates a send or receive and enters the amount to create a QR code for Bob to scan and confirm.',
  'ng-wallet:feature4': 'All amounts and balances are entered and displayed in the Guaraní currency (₲, PYG).',
  'ng-wallet:feature5': 'Wallet balance is tucked out of sight but easily viewable.',
  'ng-wallet:feature6': 'PIN protection.',
  'ng-wallet:feature7': 'Works seamlessly with other apps.',
  'ng-wallet:feature8': 'Light mode & night mode.',
  'ng-wallet:feature9': 'Convenient link (QR code) to share the app.',
  'ng-wallet:spec1': 'Payments are sent via the Lightning network.',
  'ng-wallet:spec2': 'Powered by LNbits (currently custodial only; configuration option for self-hosted wallets is coming soon)',
  'ng-wallet:licensing': 'This software is open source under the ',
  'ng-wallet:licensing2': '.',
  'ng-wallet:security1': 'Backup and guard the wallet ids/keys to avoid loss of funds.',
  'ng-wallet:security2': 'PIN protection guards app from unauthorized use.',
  'ng-wallet:privacy1': 'The app does not transmit any personal data other than what is obvious as part of sending and receiving.',
  'ng-wallet:privacy2': 'The custodian receives transaction details but no personally identifiable information.',

  'products': 'products',

  'bitcoin-node:title': 'Bitcoin Node',
  'bitcoin-node:blurb': 'Your Bitcoin node is your private gateway to “Don’t trust, verify” your Bitcoin  transactions, wallet addresses, account balances, fees paid, or any other data that is on the Bitcoin blockchain, as well as being your “permissionless” terminal to initiate any transaction on the Bitcoin network.',
  'bitcoin-node:subtitle': '',
  'bitcoin-node:affiliate': '',
  'bitcoin-node:content': '',

  'ant-s19j:title': 'Antminer S19J Pro+ 120TH Bitcoin Miner',
  'ant-s19j:blurb': 'Buying a miner and hosting it in Paraguay is the best way to invest in Bitcoin, because it pays you with provably virgin bitcoin directly to your own wallet at the best electricity prices.',
  'ant-s19j:subtitle': '',
  'ant-s19j:affiliate': 'Purchasing through Satoshi’s affiliate link entitles you to membership benefits for the life of the product.',
  'ant-s19j:content': '',

  'atm-2way:title': 'BitPoint Ultra 2-Way Bitcoin ATM',
  'atm-2way:blurb': 'This product provides two-way exchange between bitcoin and paper fiat currency. This can be a great way to support your customers and promote circular economy.',
  'atm-2way:subtitle': '',
  'atm-2way:affiliate': 'Purchasing through Satoshi’s affiliate link entitles you to membership benefits for the life of the product.',
  'atm-2way:content': '',

  'techcons:title': 'Technical Consultation',
  'techcons:subtitle': 'We have a range of technical knowledge and experience and can likely offer guidance on what to do and what to avoid as you advance your mission.',
  'tech-consult:desc1a': 'Satoshi provides comprehensive technical service to assist you in all aspects of Bitcoin and Bitcoin-related technologies. To get started, reserve a consultation today by visiting our stall in the ',
  'tech-consult:desc1b': 'community market',
  'tech-consult:desc1c': ' to place an online order for your first consultation. Please include sufficient contact information and scheduling requirements. If we are unable to contact you or fulfill your request for consultation for any reason, your reservation will be refunded. Once you have successfully reserved your consultation, you will be contacted to confirm scheduling before the actual consultation.',

  'webdev:title': 'Website Development',
  'webdev:blurb': 'Creating a virtual storefront gives you a competitive advantage by allowing customers to get to know you and order products for delivery without leaving their home.',
  'webdev-link-kludge': '/contact?n=1&m=Hi%2C%20I%20would%20like%20to%20ask%20a%20question%20about%20website%20development.%0A...%0A',

  'compfix:title': 'Computer Repair',
  'compfix:blurb': 'Cleaning and repair services for computer hardware and software.',
  'compfix-link-kludge': '/contact?n=1&m=Hi%2C%20I%20would%20like%20to%20ask%20a%20question%20about%20computer%20repair.%0A...%0A',

  'market:title': 'Community Market Online',
  'market:subtitle': 'The online market is a place where you can find local producers and service providers and make purchases online for delivery to select areas.',
  '(default)': '',
  '(default).1': `Quick links: {link:https://mercado.satoshidnc.com^Shop Online Now!} | {link:https://btcmap.org/map#12/-25.23680/-57.43610^View Community Map}`,
  //'(default).2': 'Community Market Online: naddr1qqjxxwpkxdjkycfc94snjvnx956rzctr943xvvmp956xgc35vs6rycmpxscn2qg5waehxw309aex2mrp0yhxgctdw4eju6t0qyv8wumn8ghj7un9d3shjtnndehhyapwwdhkx6tpdsq36amnwvaz7tmwdaehgu3dwp6kytnhv4kxcmmjv3jhytnwv46qzxthwden5te0dehhxarj9eax2cn9v3jk2tnrd3hh2eqpramhxue69uhkummnw3ezuampd3kx2ar0veekzar0wd5xjtnrdakszxnhwden5te0wfjkccte9eekzar0wd5xjerwvvhxxmmdqgs2nrsnurgkrmrf7rqc07lvp2c4s8qncn996gg6y686wgspuwr9mlcrqsqqqa2r7rh4ee',
  'What makes this a great platform': 'What makes this a great platform',
  'What makes this a great platform.1': 'Peer-to-peer. Vendors and customers can interact directly, if desired, and vendors can take their client relationships wherever they go.',
  'What makes this a great platform.2': 'Non-exclusive. Satoshi serves to connect customers to vendors, but the protocols are open for both sides to host or use other marketplace venues as well.',
  'What makes this a great platform.3': 'Privacy-respecting. Communications are secured by your private keys and you share only the information you wish to share.',
  'What makes this a great platform.4': 'Lightning-enabled. Pay instantly and securely with any lightning-compatible wallet.',
  'How does Satoshi profit?': 'How does Satoshi profit?',
  'How does Satoshi profit?.1': 'Satoshi earns by providing vendors with training courses and/or the initial effort to get products online.',
  'How does Satoshi profit?.2': 'The goal is to foster a thriving peer-to-peer network that is not dependent on Satoshi as a single point of failure.',
  'How does Satoshi profit?.3': '(If you would like to host a marketplace for redundancy and/or variety, Satoshi can provide guidance.)',
  'How it works-customers and clients': 'How the online market works for customers and clients',
  'How it works-customers and clients.1': '1: Shop. 2: Check-out. 3: Follow up with any additional communication needed regarding delivery or pick-up.',
  'How it works-customers and clients.2': `btn:[Start Shopping Now!](https://mercado.satoshidnc.com)`,
  'How it works-businesses': 'How the online market works for businesses',
  'How it works-businesses.1': 'Individuals or businesses may host their own stall in the market, or they may utilize the services of Satoshi in varying degrees to handle their online orders.',
  'How it works-businesses.2': 'The ideal option for the tech-saavy entrepreneur is to host his/her own wallet and product stall and simply request to be listed on the Satoshi marketplace page free of charge.',
  'How it works-businesses.3': 'The next best option for the industrius business owner is to utilize Satoshi to host his/her product stall for a negligable cost, and build the product list and manage the orders on a daily basis his/herself.',
  'How it works-businesses.4': 'The videos below will be helpful for those who want to pursue one of those first two options. Finally, the easiest, hands-free option for busy proprietors is to request that Satoshi handle the processing of online sales on a commission basis.',
  'Get started': 'Get started in any of the following ways:',
  'Get started.1': '→ Join our {link:https://t.me/+7V5WE-6pjSEwZjEx^Telegram group} for support. (Telegram supports tap-to-translate for any language. If you need help, just ask.)',
  'Get started.2': '→ {link:contact?n=1&m='+encodeURIComponent('Hi, I would like Satoshi to host my store in the online community market!')+'^Contact Satoshi} to get started with full service.',
  'Get started.3': '→ Learn more about the course {link:courses/distros^distros-course:title}.',
  'Get started.4': '→ {link:contact?n=1&m='+encodeURIComponent('Hi, I would like Satoshi to add my product stall to the online community market!')+'^Contact Satoshi} to link to a product stall that you already manage.',
  'Get started.5': '→ Access our {link:https://lnbits.satoshidnc.com^self-serve system} (or host your own) to set up and manage your own merchant account following this short tutorial:',
  'Get started.6': '{listitem}1. {link:media/2023/diy/01.mp4^Creating a free merchant account in Paraguay} (2.8 min)', // 2:49
  'Get started.7': '{listitem}2. {link:media/2023/diy/02.mp4^Create an online store} (2 min)', // 1:55
  'Get started.8': '{listitem}3. {link:media/2023/diy/03.mp4^Create shipping zones} (2.1 min)', // 2:08
  'Get started.9': '{listitem}4. {link:media/2023/diy/04.mp4^Create a stall} (1.5 min)', // 1:30
  'Get started.10': '{listitem}5. {link:media/2023/diy/05.mp4^Add products to your online store} (4 min)', // 4:03
  'Get started.11': '{listitem}6. {link:media/2023/diy/06.mp4^Enable order processing} (39 sec)', // 
  'Get started.12': '{listitem}7. {link:media/2023/diy/07.mp4^Adding your store to the online market} (3.3 min)', // 3:16
  'Get started.13': '{listitem}8. {link:media/2023/diy/08.mp4^Example of a customer purchase} (2.3 min)', // 2:18
  'Get started.14': '{listitem}9. {link:media/2023/diy/09.mp4^Check your orders} (2.3 min)', // 2:19
  'Get started.15': '{listitem}10. {link:media/2023/diy/10.mp4^Closing remarks} (3 min)', // 2:54

'market-naddr-link': `https://mercado.satoshidnc.com`,

'pubkey:casa-vegana': '3e5a2a429e329541d83e6ce912976d6a00ce4c71ef8e916761f611a388cf17d9',
'stall:casa-vegana': 'GfH7kTTpfnqJUdB9kbm3Pb',

'pubkey:more-energy': 'e099882085e9f43b5d6171c63c560dbc9c96dc49612590693d1477a4bae2a215',
'stall:more-energy': 'mny7wbCPVVkLzHzXHxbUZb',

}}

export default init_en